import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { css } from '@compiled/react';

import {
	CustomBlock,
	MetadataBlock,
	PreviewBlock,
	SnippetBlock,
	TitleBlock,
	ElementName,
	SmartLinkSize,
	SmartLinkTheme,
	SmartLinkPosition,
	FooterBlock,
	ActionName,
	MediaPlacement,
} from '@atlaskit/smart-card';
import { CardSSR as SmartCardSSR } from '@atlaskit/smart-card/ssr';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { N200 } from '@atlaskit/theme/colors';
import { WidthObserver } from '@atlaskit/width-detector';
import { fg } from '@atlaskit/platform-feature-flags';

import { EmojiRenderer } from '../EmojiRenderer';

import {
	getTitleBlockStyles,
	CommonCarouselWrapperStyles,
	getCustomImageUploadStyles,
	HeroCardWrapper,
	floatingEmojiStyles as floatingEmojiStylesOld,
} from './cardComponentsStyles';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import type { HeroCardProps } from './HeroCard';

type HeroCardLinkComponentProps = {
	emojiId: string | null;
	isDisabledTextColor?: boolean;
	previewImagePlacement?: MediaPlacement;
	handleViewAction: () => void;
	throttledSetWidth: any;
	isVerticalLayout: boolean;
	onError: any;
	onResolve: () => void;
	showDescriptionPlaceholderText: boolean;
	isUnauthorized: boolean;
} & Omit<
	HeroCardProps,
	| 'isInViewMode'
	| 'onCardSucceeded'
	| 'onCardFailed'
	| 'createAnalyticsEvent'
	| 'analyticsSource'
	| 'extensionType'
	| 'imagePosition'
	| 'imageAltText'
>;

const descriptionStylesOverrideOld = (showDescriptionPlaceholderText) => ({
	'> span': {
		font: token('font.body', fontFallback.body.medium),
		textAlign: 'left',
		color: showDescriptionPlaceholderText ? token('color.text.subtlest', N200) : 'unset',
	},
});

const emojiStylesOverrideOld = {
	'> span': {
		margin: `${token('space.025', '2px')} 0`,
	},
};

const i18n = defineMessages({
	viewButtonText: {
		id: 'custom-sites-extensions.hero-card.view',
		defaultMessage: 'View',
		description:
			'Text shown on the button on the largest card of the carousel component to view the content',
	},
});

const footerBlockStylesOverrideOld = {
	flexGrow: 1,
	' > div': {
		alignSelf: 'flex-end',
		maxHeight: 'fit-content',
	},
	' > span': {
		alignSelf: 'flex-end',
	},
};

const imageUploadLeftStyles = css({
	position: 'absolute',
	width: 'var(--preview-block-width)',
	top: token('space.0', '0px'),
	bottom: token('space.0', '0px'),
	left: token('space.0', '0px'),
});

const imageUploadRightStyles = css({
	marginLeft: 'calc(var(--container-gap-left) * -1)',
	marginRight: 'calc(var(--container-gap-right) * -1)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		marginTop: 'calc(var(--container-padding) * -1)',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		marginBottom: 'calc(var(--container-padding) * -1)',
	},
});

const floatingEmojiStyles = css({
	position: 'relative',
	top: token('space.negative.150', '-12px'),
	bottom: token('space.025', '2px'),
	height: '0px',
	overflow: 'visible',
});

const emojiStylesOverride = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		margin: `${token('space.025', '2px')} 0`,
	},
});

const descriptionStylesOverride = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		font: token('font.body', fontFallback.body.medium),
		textAlign: 'left',
	},
});

const descriptionStylesOverrideShowPlaceholder = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		color: token('color.text.subtlest', N200),
	},
});

const descriptionStylesOverrideHidePlaceholder = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		color: 'unset',
	},
});

const footerBlockStylesOverride = css({
	flexGrow: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	' > div': {
		alignSelf: 'flex-end',
		maxHeight: 'fit-content',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	' > span': {
		alignSelf: 'flex-end',
	},
});

const titleBlockStyles = css({
	marginTop: token('space.0', '0px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		fontWeight: token('font.weight.medium'),
		flexShrink: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:visited, a:focus, a:hover, a:active': {
		textDecoration: 'none',
		pointerEvents: 'none',
	},
});

const titleBlockUnauthorizedStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		display: 'none',
	},
});

const titleBlockLinkTextColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		color: token('color.text'),
	},
});

const titleBlockLinkTextSubtlestColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		color: token('color.text.subtlest'),
	},
});

export const HeroCardLinkComponent = ({
	contentId,
	cardId,
	link,
	title,
	imageSrc,
	cardHeight,
	isAvatarShown,
	isPublishDateShown,
	description,
	isVerticalLayout,
	previewImagePlacement,
	isEmptyCard = false,
	handleViewAction,
	throttledSetWidth,
	onError,
	onResolve,
	showDescriptionPlaceholderText,
	isDisabledTextColor = false,
	emojiId,
	ariaLabel,
	isUnauthorized,
}: HeroCardLinkComponentProps) => {
	const { showUnsplashImage, showExternalSiteImage, showCustomUploadedImage } = getImageTypeToShow({
		imageSrc,
	});

	return fg('bandicoots-compiled-migration-smartcard') ? (
		<CommonCarouselWrapperStyles isHeroCard>
			<HeroCardWrapper
				cardHeight={cardHeight}
				isPublishDateShown={isPublishDateShown}
				isVerticalLayout={isVerticalLayout}
				aria-label={ariaLabel}
				tabIndex={0}
			>
				<SmartCardSSR
					id={cardId}
					appearance="block"
					frameStyle="hide"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: false,
						size: SmartLinkSize.XLarge,
						hideElevation: true,
					}}
					url={link}
					onResolve={onResolve}
					onError={onError}
				>
					{(showUnsplashImage || showExternalSiteImage) && (
						<PreviewBlock
							ignoreContainerPadding
							placement={previewImagePlacement}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage && (
						<CustomBlock
							css={[
								previewImagePlacement === MediaPlacement.Left
									? imageUploadLeftStyles
									: imageUploadRightStyles,
							]}
						>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								shouldShowErrorMessage
							/>
						</CustomBlock>
					)}
					<CustomBlock
						css={[isVerticalLayout ? floatingEmojiStyles : emojiStylesOverride]}
						testId="smart-block-link-card-emoji-container"
					>
						<EmojiRenderer emojiId={emojiId} height={24} />
					</CustomBlock>
					<TitleBlock
						hideIcon
						metadata={!!isAvatarShown ? [{ name: ElementName.OwnedByGroup }] : []}
						position={SmartLinkPosition.Top}
						text={title}
						size={SmartLinkSize.XLarge}
						maxLines={2}
						anchorTarget="_blank"
						css={[
							titleBlockStyles,
							isUnauthorized && titleBlockUnauthorizedStyles,
							isDisabledTextColor
								? titleBlockLinkTextSubtlestColorStyles
								: titleBlockLinkTextColorStyles,
						]}
					/>
					{!!isPublishDateShown && <MetadataBlock primary={[{ name: ElementName.ModifiedOn }]} />}
					{!isUnauthorized && (
						<SnippetBlock
							text={description}
							css={[
								descriptionStylesOverride,
								showDescriptionPlaceholderText
									? descriptionStylesOverrideShowPlaceholder
									: descriptionStylesOverrideHidePlaceholder,
							]}
							maxLines={isVerticalLayout ? 3 : 6}
						/>
					)}
					{!isEmptyCard && (
						<FooterBlock
							css={footerBlockStylesOverride}
							alwaysShow
							hideProvider
							actions={[
								{
									name: ActionName.CustomAction,
									onClick: handleViewAction,
									hideIcon: true,
									content: <FormattedMessage {...i18n.viewButtonText} />,
									href: link,
									ariaLabel: title,
								},
							]}
							size={SmartLinkSize.XLarge}
						/>
					)}
				</SmartCardSSR>
				<WidthObserver setWidth={throttledSetWidth} offscreen />
			</HeroCardWrapper>
		</CommonCarouselWrapperStyles>
	) : (
		<CommonCarouselWrapperStyles isHeroCard>
			<HeroCardWrapper
				cardHeight={cardHeight}
				isPublishDateShown={isPublishDateShown}
				isVerticalLayout={isVerticalLayout}
				aria-label={ariaLabel}
				tabIndex={0}
			>
				<SmartCardSSR
					id={cardId}
					appearance="block"
					frameStyle="hide"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: false,
						size: SmartLinkSize.XLarge,
						hideElevation: true,
					}}
					url={link}
					onResolve={onResolve}
					onError={onError}
				>
					{(showUnsplashImage || showExternalSiteImage) && (
						<PreviewBlock
							ignoreContainerPadding
							placement={previewImagePlacement}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage && (
						<CustomBlock
							overrideCss={getCustomImageUploadStyles(previewImagePlacement, true) as any}
						>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								shouldShowErrorMessage
							/>
						</CustomBlock>
					)}
					<CustomBlock
						overrideCss={
							isVerticalLayout ? (floatingEmojiStylesOld as any) : (emojiStylesOverrideOld as any)
						}
						testId="smart-block-link-card-emoji-container"
					>
						<EmojiRenderer emojiId={emojiId} height={24} />
					</CustomBlock>
					<TitleBlock
						hideIcon
						metadata={!!isAvatarShown ? [{ name: ElementName.OwnedByGroup }] : []}
						position={SmartLinkPosition.Top}
						text={title}
						size={SmartLinkSize.XLarge}
						maxLines={2}
						anchorTarget="_blank"
						overrideCss={
							getTitleBlockStyles({
								isHeroCard: true,
								isDisabledTextColor,
								disablePointerEvents: true,
								isUnauthorized,
							}) as any
						}
					/>
					{!!isPublishDateShown && <MetadataBlock primary={[{ name: ElementName.ModifiedOn }]} />}
					{!isUnauthorized && (
						<SnippetBlock
							text={description}
							overrideCss={descriptionStylesOverrideOld(showDescriptionPlaceholderText) as any}
							maxLines={isVerticalLayout ? 3 : 6}
						/>
					)}
					{!isEmptyCard && (
						<FooterBlock
							overrideCss={footerBlockStylesOverrideOld as any}
							alwaysShow
							hideProvider
							actions={[
								{
									name: ActionName.CustomAction,
									onClick: handleViewAction,
									hideIcon: true,
									content: <FormattedMessage {...i18n.viewButtonText} />,
									href: link,
									ariaLabel: title,
								},
							]}
							size={SmartLinkSize.XLarge}
						/>
					)}
				</SmartCardSSR>
				<WidthObserver setWidth={throttledSetWidth} offscreen />
			</HeroCardWrapper>
		</CommonCarouselWrapperStyles>
	);
};
